import React from "react"
import Heading from "../../common/Heading"
import "./hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <Heading title='WELCOME TO SSRO ' subtitle="Embark on cosmic exploration with SSRO! We're your portal to space adventures, simplifying the marvels of the universe. Join us for a thrilling journey among the stars, where discovery awaits at every cosmic turn!" />
        </div>
      </section>
    </>
  )
}

export default Hero
