export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "events",
    path: "/events",
  },
  {
    text: "team",
    path: "/team",
  },
  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Innovations",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Researches",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Developments",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Designing",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Educations",
  },
]
export const list = [
  {
    id: 1,
    cover: "../images/events/cubesat.png",
    name: "Cubesat Programme",
    location: "CubeSat research develops small, standardized 10 cm satellites for diverse scientific experiments and tests in space. These cost-effective platforms democratize space access, enabling academic institutions and small entities to contribute significantly. CubeSats, typically deployed as secondary payloads, offer quick, affordable access to the space environment, fostering innovation in aerospace engineering and space science endeavors.",
    category: "Active Research",
  },
  {
    id: 2,
    cover: "../images/events/drone.jpg",
    name: "Latest AI Drone",
    location: "AI drone research advances unmanned systems with cutting-edge algorithms for autonomy and navigation. Drones play key roles in agriculture, environmental monitoring, and disaster response. Ongoing efforts focus on challenges like swarm intelligence and real-time decision-making. Latest strides showcase efficient and intelligent aerial operations. Researchers explore adaptive learning for enhanced capabilities in this dynamic field redefining aerial technology.",
    category: "Future Research",
  },
  {
    id: 3,
    cover: "../images/events/rover.jpg",
    name: "Rover Technologies",
    location: "Rover technology research centers on developing and deploying advanced robotic systems for planetary exploration, leveraging sophisticated technologies for autonomous navigation and scientific data collection on celestial bodies. These rovers, equipped with cutting-edge instrumentation, play a pivotal role in expanding our understanding of distant planets, paving the way for future manned missions and technological advancements in space.",
    category: "Future Research",
  }
]
export const awards = [
  {
    icon: <i class='fa-solid fa-book'></i>,
    num: "03",
    name: "Research",
  },
  {
    icon: <i class='fa-solid fa-eye'></i>,
    num: "01",
    name: "Active Research",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "02",
    name: "Future Research",
  },
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "00",
    name: "Finished Research",
  },
]
export const location = [
  {
    id: 1,
    name: "NASA, United State",
    cover: "./images/location/nasa.webp",
  },
  {
    id: 2,
    name: " ESA, France",
    cover: "./images/location/esa.jpg",
  },
  {
    id: 3,
    name: "ROSCOSMOS, Russia",
    cover: "./images/location/roscosmos.jpeg",
  },
  {
    id: 4,
    name: "ISRO, India",
    cover: "./images/location/isro.jpg",
  },
  {
    id: 5,
    name: "JAXA, Japan",
    cover: "./images/location/jaxa.jpg",
  },
  {
    id: 6,
    name: "ASA, Australia",
    cover: "./images/location/asa.jpg",
  },
]
export const team = [
  {
    cover: "../images/team/team.jpg",
    address: "Jaffna, Srilanka",
    name: "MR Sarujan P",
    icon: [<a href="https://facebook.com/" rel="noreferrer" target="_blank"><i class='fa-brands fa-facebook-f'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-linkedin'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-twitter'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-instagram'></i></a>],
  },
  {
    cover: "../images/team/team.jpg",
    address: "Jaffna, Srilanka",
    name: "MR Sarujan P",
    icon: [<a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-facebook-f'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-linkedin'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-twitter'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-instagram'></i></a>],
  },
  {
    cover: "../images/team/team.jpg",
    address: "Jaffna, Srilanka",
    name: "MR Sarujan P",
    icon: [<a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-facebook-f'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-linkedin'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-twitter'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-instagram'></i></a>],
  },
  {
    cover: "../images/team/team.jpg",
    address: "Jaffna, Srilanka",
    name: "MR Sarujan P",
    icon: [<a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-facebook-f'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-linkedin'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-twitter'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-instagram'></i></a>],
  },
  {
    cover: "../images/team/team.jpg",
    address: "Jaffna, Srilanka",
    name: "MR Sarujan P",
    icon: [<a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-facebook-f'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-linkedin'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-twitter'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-instagram'></i></a>],
  },
  {
    cover: "../images/team/team.jpg",
    address: "Jaffna, Srilanka",
    name: "MR Sarujan P",
    icon: [<a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-facebook-f'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-linkedin'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-twitter'></i></a>, <a href="" rel="noreferrer" target="_blank"><i class='fa-brands fa-instagram'></i></a>],
  },
]
export const footer = [
  {
    title: "COMPANY LINKS",
    text: [{ list: "About", link: "/about" }, { list: "Services", link: "/services" }, { list: "Events", link: "events" }, { list: "Team", link: "team" }],
  },
  {
    title: "USEFULL LINKS",
    text: [{ list: "NASA", link: "https://www.nasa.gov/" }, { list: "ESA", link: "https://www.esa.int/" }, { list: "SpaceX", link: "https://www.spacex.com/" }, { list: "Hubble", link: "https://hubblesite.org/" }],
  },
  {
    title: "QUICK LINKS",
    text: [{ list: "Space.com", link: "https://www.space.com/" }, { list: "Iau.org", link: "https://www.iau.org/" }, { list: "Seti.org", link: "https://www.seti.org/" }, { list: "Planetary.org", link: "https://www.planetary.org/" }],
  },
]
