import React from "react"
import Heading from "../common/Heading"
import "./about.css"
import { useHistory } from "react-router-dom"

const About = () => {
  const history = useHistory()

  return (
    <>
      <section className='about'>
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='ABOUT OUR ORGANIZATION' subtitle='ABOUT SRILANKA SPACE RESEARCH ORGANIZATION' />

            <p className="atext">SSRO (Sri Lanka Space Research Organization) is a non-profit organization dedicated to spearheading research, innovation, education, development, and design initiatives in Sri Lanka. At the heart of our mission is the pursuit of excellence in space-related endeavors, with a commitment to advancing scientific knowledge and technological capabilities within the nation. We aim to be a catalyst for transformative change by fostering a collaborative environment that encourages cutting-edge research, facilitates innovative solutions, and provides educational opportunities for aspiring professionals in the field.</p>
            <p className="atext">Our organization operates at the intersection of academia, industry, and government, striving to bridge gaps and create synergies that propel Sri Lanka to new heights in space exploration and technology. Through strategic partnerships, research projects, and educational programs, SSRO envisions a future where Sri Lanka actively contributes to the global space community while inspiring and nurturing the next generation of scientists, engineers, and leaders. Join us on this exciting journey as we explore the limitless possibilities that space research offers for the benefit of our nation and the world.</p>
            <button onClick={() => history.push('/contact')}>GET STARTED</button>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
