import React, { useState, useEffect } from 'react';
import Header from "../common/header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../home/Home";
import Footer from "../common/footer/Footer";
import About from "../about/About";
import Team from "../home/team/Team";
import Blog from "../event/Event";
import Services from "../services/Services";
import Contact from "../contact/Contact";
import Loader from '../loader/Loader'; 

const Pages = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    fetchData();
  }, []); 

  return (
    <>
      <Router>
        {loading ? (
          <Loader />
          ) : (
          <>
            <Header />
              <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/about' component={About} />
                <Route exact path='/services' component={Services} />
                <Route exact path='/events' component={Blog} />
                <Route exact path='/team' component={Team} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='*' component={Home} />
              </Switch>
            <Footer />
          </>
        )}
      </Router>
    </>
  );
};

export default Pages;
