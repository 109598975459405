import React from "react"
import { footer } from "../../data/Data"
import "./footer.css"

const Footer = () => {
  return (
    <>

      <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
              <h2>DO YOU NEED CONNECT WITH US?</h2>
              <p>Subscribe to stay informed about the latest developments and initiatives from Sri Lanka's Space Research Organization. Get in touch with us as we explore the frontiers of space exploration together.</p>

              <div className='input flex'>
                <input type='text' placeholder='Email Address' />
                <button>SUBSCRIBE</button>
              </div>
            </div>
          </div>

          <span className="sub-container">
            {footer.map((val) => (
              <div className='box'>
                <h3>{val.title}</h3>
                <ul>
                  {val.text.map((items) => (
                    <li><a className="link" rel="noreferrer" href={items.link} target="_blank"> {items.list} </a></li>
                  ))}
                </ul>
              </div>
            ))}
          </span>
        </div>
      </footer>
      <div className='legal'>
        <span>© {new Date().getFullYear()} SSRO. Designd By <a className="me" target="_blank" href="https://wa.me/+94775428041/?text=Hello, Saru!" rel="noreferrer">Sarujan</a></span>
      </div>
    </>
  )
}

export default Footer
