import React from "react"
import "../home/featured/Featured.css"
import Featured from "../home/featured/Featured"

const Services = () => {
  return (
    <>
      <section className='services mb'>
        <Featured />
      </section>
    </>
  )
}

export default Services
