import React from "react"
import Recent from "../home/recent/Recent"
import "../home/recent/recent.css"

const Blog = () => {
  return (
    <>
      <section className='blog-out mb'>
        <Recent />
      </section>
    </>
  )
}

export default Blog
