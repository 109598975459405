import React from "react"
import "./contact.css"
import Heading from "../common/Heading"

const Contact = () => {
  return (
    <>
      <section className='recent padding contact'>
        <div className='container'>
          <Heading title='CONTACTUS' subtitle='GET IN TOUCH WITH US AS WE EXPLORE THE FRONTIERS OF SPACE EXPLORATION TOGETHER' />
          <div >
            <form action="" class='shadow'>
              <div className="flex">
                <input type='text' placeholder='Name' />
                <input type='email' placeholder='Email' />
              </div>
              <div className="flex">
                <input type='tel' placeholder='Phone Number' />
                <input type='text' placeholder='Subject' />
              </div>
              <textarea rows='5' placeholder='Message'></textarea>
              <button>SEND MESSAGE</button>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
