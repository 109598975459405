import React from 'react';
import './loader.css';

const Loader = () => {
  return (
    <div className="loader-container">
        <div className='logo'>
            <img src='./images/logo.png' alt='logo' />
        </div>
    </div>
  );
};

export default Loader;
